import React from "react";
import {
  AppShell,
  LogoFreshPrintSvg,
  LogoLedArtSvg,
  LogoNestMediaSvg,
  LogoSenseRadioSvg,
  RadioPage,
  SocialButtonsSvg,
  StripedBackground,
  ThemeNameTags,
  DefaultFooter,
  LogoAppStoreSvg,
  LogoGooglePlaySvg,
  SpanBlock,
  SpeakerBackgroundPlayerSvg,
} from "@molitio/ui-core";

export const SenseRadio: React.FC = () => {
  const embededImageSource =
    "https://s3.eu-west-1.amazonaws.com/filestore.molitio.org/nest-media/sense-radio/image-store/radio_sense_effect.png";

  return (
    <AppShell themeName={ThemeNameTags.BLACK_YELLOW} applyGlobalStyleRules>
      <RadioPage
        radio={{
          mediaSource: {
            sourceUrl: "https://stream1.virtualisan.net/prx/4800/live.mp3",
            mediaType: "audio/mpeg",
          },
          alternativeMediaSource: {
            sourceUrl: "https://stream1.virtualisan.net/prx/4800/stream.mp3",
            mediaType: "audio/mpeg",
          },
          preLoad: "none",
        }}
        playerElement={
          <SpeakerBackgroundPlayerSvg embededImageSrc={embededImageSource} />
        }
        logo={<LogoSenseRadioSvg dimensions={{ height: "8em" }} />}
        socialButtons={
          <SocialButtonsSvg
            dimensions={{ height: "3em" }}
            facebookUrl="https://www.facebook.com/radiosensehungary"
            instagramUrl=" https://www.instagram.com/radiosensehungary"
            youtubeUrl="https://www.youtube.com/c/radiosensehungary"
            externalUrl="https://hearthis.at/radiosensehungary"
          />
        }
        background={
          <StripedBackground backgroundEffectSrc={embededImageSource} />
        }
        pageFooter={
          <DefaultFooter
            leftColumnElements={[
              <LogoFreshPrintSvg
                dimensions={{ height: "2.5em" }}
                linkUrl="https://www.facebook.com/freshprint"
              />,
              <LogoLedArtSvg
                dimensions={{ height: "2.5em" }}
                linkUrl="https://www.facebook.com/hangfenyszinpad"
              />,
              <LogoNestMediaSvg
                dimensions={{ height: "2.5em" }}
                linkUrl="https://www.nestmedia.hu"
              />,
            ]}
            centerColumnElements={[
              <SpanBlock
                rem={0.7}
                color={"secondary"}
                marginTop={"0.2rem"}
                center
              >
                Médiaajánlat: hello@radiosense.hu
              </SpanBlock>,
              <SpanBlock
                rem={0.5}
                color={"secondary"}
                marginTop={"0.1rem"}
                center
              >
                © 2022 Radio Sense Hungary. All rights reserved.
                <br />
                created by nestmedia, v1.4.2
              </SpanBlock>,
            ]}
            rightColumnElements={[
              <LogoGooglePlaySvg
                dimensions={{ height: "2em" }}
                opacity={0}
                linkUrl="https://play.google.com/store"
              />,
              <LogoAppStoreSvg
                dimensions={{ height: "2em" }}
                opacity={0}
                linkUrl="https://www.apple.com/app-store/"
              />,
            ]}
          />
        }
      />
    </AppShell>
  );
};
